var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.message
    ? _c("CAlert", { attrs: { color: "warning" } }, [
        _vm._v(
          _vm._s(_vm.$t("components.ErrorMessage.Text")) +
            ": " +
            _vm._s(_vm.message)
        )
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }