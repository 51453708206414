var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("CButton", {
    directives: [
      {
        name: "t",
        rawName: "v-t",
        value: "components.forms.DiscardChangesButton.button",
        expression: "'components.forms.DiscardChangesButton.button'"
      }
    ],
    attrs: { color: "warning" },
    on: { click: _vm.discardChanges }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }