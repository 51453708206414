var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCardBody",
    [
      !_vm.ready
        ? _c("div", { staticClass: "text-center" }, [_c("CSpinner")], 1)
        : _vm._e(),
      _vm.ready ? [_vm._t("default")] : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }