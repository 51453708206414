var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "CCard",
    {
      staticClass: "validated-form-card",
      attrs: { "data-testid": _vm.testid }
    },
    [
      _c("CCardHeader", [
        _c(
          "span",
          [
            _vm.partSaveStatusType
              ? _c("PartSaveStatus", {
                  attrs: { status: _vm.partSaveStatusType }
                })
              : _vm._e(),
            _vm._v(" " + _vm._s(_vm.$t(_vm.titleTranslationKey)) + " "),
            _vm.titleTooltipTranslationKey
              ? _c("CIcon", {
                  directives: [
                    {
                      name: "c-tooltip",
                      rawName: "v-c-tooltip",
                      value: {
                        appendToBody: true,
                        content: _vm.$t(_vm.titleTooltipTranslationKey)
                      },
                      expression:
                        "{ appendToBody: true, content: $t(titleTooltipTranslationKey) }"
                    }
                  ],
                  attrs: { name: "cis-info-circle" }
                })
              : _vm._e()
          ],
          1
        )
      ]),
      _c(
        "LoadingCardBody",
        { attrs: { ready: _vm.ready } },
        [
          _c("ErrorMessage", {
            attrs: { message: _vm.errorMessage || _vm.additionalErrorMessage }
          }),
          _vm._t("default")
        ],
        2
      ),
      _vm.onSave
        ? _c(
            "CCardFooter",
            [
              _c("SaveButton", {
                ref: "saveButton",
                attrs: {
                  model: _vm.model,
                  onSave: _vm.internalOnSave,
                  additionalErrorMessage: _vm.additionalErrorMessage,
                  saving: _vm.saving
                },
                on: { setErrorMessage: _vm.setErrorMessage }
              }),
              _vm.showDiscardButton
                ? _c("DiscardChangesButton", {
                    on: {
                      discardChanges: function($event) {
                        return _vm.$emit("discardChanges")
                      }
                    }
                  })
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }